import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import dpp from "../../Assets/Projects/dpp.png";
import sanad from "../../Assets/Projects/sanad.png";
import va from "../../Assets/Projects/va.png";
import ondemand from "../../Assets/Projects/ondemand.png";
import husl from "../../Assets/Projects/husl.png";
function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={dpp}
              isBlog={false}
              title="Dubai Public Parks"
              description="Lead the backend team to utilize .Net 6 and microservices architecture to develop the backend. Integrated various Azure services such as Azure App Service, Service Bus, and Azure Gateway. Implemented payment solutions, social logins, and UAE Pass."
              // ghLink="https://github.com/soumyajit4419/Chatify"
              demoLink="https://dubaipublicparks.ae/home/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={va}
              isBlog={false}
              title="Virtual Academy"
              description="Developed backend using .Net for the Dubai Ministry of Industry and Advanced Technology. Contributed to user authentication and secure access management."
              // ghLink="https://github.com/soumyajit4419/Bits-0f-C0de"
              demoLink="https://virtualacademy.moiat.gov.ae/authentication/home"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={sanad}
              isBlog={false}
              title="Sanad"
              description="Worked as a Full-Stack Developer on a video call customer service SaaS using Node.js and Angular. Implemented real-time communications using WebSockets and developed backend APIs."
              // ghLink="https://github.com/soumyajit4419/Editor.io"
              demoLink="https://sanadrelay.cda.gov.ae/home"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ondemand}
              isBlog={false}
              title="My own SaaS ( On Demand )"
              description="Adapted a logistics delivery system from CodeCanyon for the Egyptian market, built using Laravel for the web app and Flutter for the mobile apps. Automated the parcel delivery process, resulting in 10 active clients with systems deployed on individual DigitalOcean servers."
              // ghLink="https://github.com/soumyajit4419/Plant_AI"
              demoLink="http://ondemanddemo.site/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={husl}
              isBlog={false}
              title="Web 3 Development"
              description="Led the development of ERC20 tokens for reward distribution via Metamask, designed and deployed secure NFT smart contracts, built APIs and React components to enhance user campaigns, and contributed to system design and architecture for scalable solutions."

              // ghLink="https://github.com/soumyajit4419/Plant_AI"
              demoLink="https://thehusl.io/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;

import React, { useState } from "react";
import Tilt from "react-parallax-tilt";
import myImg from "../../Assets/avatar2.png";
import { Container, Row, Col } from "react-bootstrap";

function Avatar() {
    const [clickCount, setClickCount] = useState(0);
    const [isFlipped, setIsFlipped] = useState(false);
  
    const handleClick = () => {
      const newCount = clickCount + 1;
      setClickCount(newCount);
  
      if (newCount === 3) {
        setIsFlipped(!isFlipped);
        setClickCount(0); // Reset click count after flipping
      }
    };
  
    return (
      <Col md={4} className="myAvtar" onClick={handleClick}>
        <Tilt>
          <div className={`avatar-container ${isFlipped ? "flipped" : ""}`}>
            <div className="avatar-front">
              <img src={myImg} className="img-fluid" alt="avatar" />
            </div>
            <div className="avatar-back">
              <p>PLEASE KEEP DISTANCE</p>
            </div>
          </div>
        </Tilt>
      </Col>
    );
  }
  
  export default Avatar;
import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiVisualstudiocode,
  SiPostman,
  SiSlack,
  SiVercel,
  SiMysql ,
  SiMicrosoftazure ,
  SiRedis ,
  SiFirebase,
} from "react-icons/si";
import {
  DiGit,
  DiAws,
  DiDigitalOcean,
  DiDocker ,
  DiLinux,
  DiRedhat

} from "react-icons/di";
function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiMysql />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVisualstudiocode />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMicrosoftazure />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostman />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiSlack />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVercel />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <DiAws /> 
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <DiDigitalOcean  /> 
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <DiDocker  /> 
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiFirebase />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiRedis />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <DiRedhat   /> 
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <DiLinux   /> 
      </Col>
    </Row>
  );
}

export default Toolstack;
